var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.user && _vm.roleOptions)?_c('div',[_c('b-media',{attrs:{"no-body":""}},[_c('b-media-aside',[_c('b-link',[_c('b-img',{ref:"previewEl",attrs:{"rounded":"","src":_vm.user.avatar ? _vm.user.avatar : '',"height":"80"}})],1)],1),_c('b-media-body',{staticClass:"mt-75 ml-75"},[_c('b-button',{staticClass:"mb-75 mr-75",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.$refs.refInputEl.$el.click()}}},[_vm._v(" 上传头像 ")]),_c('b-form-file',{ref:"refInputEl",attrs:{"accept":".jpg, .png, .gif","name":"uploadFile","hidden":true,"plain":""},on:{"input":_vm.uploadFile},model:{value:(_vm.profileFile),callback:function ($$v) {_vm.profileFile=$$v},expression:"profileFile"}}),_c('b-card-text',[_vm._v("只接受 JPG, GIF 和 PNG. 最大尺寸 800KB")])],1)],1),_c('b-form',{staticClass:"mt-2"},[_c('validation-Observer',[_c('b-row',[_c('b-col',{attrs:{"sm":"5"}},[_c('validation-provider',{attrs:{"name":"*email*","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"account-e-mail"}},[_c('label',{staticClass:"star"},[_vm._v("E-mail")]),_c('b-form-input',{attrs:{"name":"email","placeholder":"Email","disabled":_vm.user.id ? true : false},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,255186280)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"5"}},[_c('b-form-group',{attrs:{"label-for":"account-nickName"}},[_c('label',{staticClass:"star"},[_vm._v("昵称")]),_c('b-form-input',{attrs:{"placeholder":"nickName","name":"nickName"},model:{value:(_vm.user.nickName),callback:function ($$v) {_vm.$set(_vm.user, "nickName", $$v)},expression:"user.nickName"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"5"}},[_c('validation-provider',{attrs:{"name":"*手机*","rules":"required|mobile"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"account-mobile"}},[_c('label',{staticClass:"star"},[_vm._v("手机")]),_c('b-form-input',{attrs:{"name":"mobile","placeholder":"手机"},model:{value:(_vm.user.mobile),callback:function ($$v) {_vm.$set(_vm.user, "mobile", $$v)},expression:"user.mobile"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,529631710)})],1)],1),_c('b-row',[(_vm.user.id == 0)?_c('b-col',{attrs:{"sm":"5"}},[_c('validation-provider',{attrs:{"name":"*密码*","rules":"required|password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"account-password"}},[_c('label',{staticClass:"star"},[_vm._v("密码")]),_c('b-form-input',{attrs:{"name":"password","placeholder":"密码"},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4172775393)})],1):_vm._e()],1),_c('b-row',[_c('b-col',{attrs:{"sm":"5"}},[_c('validation-provider',{attrs:{"name":"*角色*","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"account-roleId"}},[_c('label',{staticClass:"star"},[_vm._v("角色")]),_c('b-form-select',{attrs:{"options":_vm.roleOptions,"disabled":_vm.user.default == 1},model:{value:(_vm.user.roleId),callback:function ($$v) {_vm.$set(_vm.user, "roleId", $$v)},expression:"user.roleId"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1304678693)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"5"}},[_c('validation-provider',{attrs:{"name":"*部门*","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"account-roleId"}},[_c('label',{staticClass:"star"},[_vm._v("部门")]),_c('b-form-select',{attrs:{"options":_vm.orgOptions,"disabled":_vm.user.default == 1},model:{value:(_vm.user.orgId),callback:function ($$v) {_vm.$set(_vm.user, "orgId", $$v)},expression:"user.orgId"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,647415877)})],1)],1),_c('b-row',[(_vm.user.id != 0)?_c('b-col',{attrs:{"sm":"5"}},[_c('b-form-group',{attrs:{"label-for":"account-"}},[_c('label',{staticClass:"star"},[_vm._v("启用/停用")]),_c('b-form-checkbox',{attrs:{"name":"check-button","switch":"","inline":""},model:{value:(_vm.user.status),callback:function ($$v) {_vm.$set(_vm.user, "status", $$v)},expression:"user.status"}})],1)],1):_vm._e()],1),_c('b-row',[_c('b-col',{staticClass:"text-right",attrs:{"cols":"5"}},[_c('b-button',{staticClass:"mt-2 mr-1",attrs:{"variant":"primary"},on:{"click":_vm.save}},[_vm._v(" 保存修改 ")]),_c('b-button',{staticClass:"mt-2",attrs:{"variant":"outline-secondary","type":"reset"},on:{"click":function($event){$event.preventDefault();return _vm.backToList.apply(null, arguments)}}},[_vm._v(" 取消返回 ")])],1)],1)],1)],1),_c('b-modal',{attrs:{"id":"modal-login","cancel-variant":"outline-secondary","ok-title":"确定","cancel-title":"Close","centered":"","title":"选择角色"}},[_c('b-form',[_c('b-form-group',[_c('label',{attrs:{"for":"email"}},[_vm._v("角色")]),_c('b-form-input',{model:{value:(_vm.user.roleId),callback:function ($$v) {_vm.$set(_vm.user, "roleId", $$v)},expression:"user.roleId"}})],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }